import React, { useState } from 'react';
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactForm from "../components/contactForm"

import conduiteAccompagnee from "../images/icons/conduite-accompagnee.svg";
import conduiteSupervisee from "../images/icons/conduite-supervisee.svg";
import permisB from "../images/icons/permis-b.svg";
import signature from "../images/signature.svg"
import road from "../images/icons/road.svg"
import next from "../images/icons/next.svg"


const days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
]

const isToday = date => {
  const today = new Date()
  return (
    today.getDate() === date.getDate() &&
    today.getMonth() === date.getMonth() &&
    today.getFullYear() === date.getFullYear()
  )
}

const getTodayLesson = lessons => {
  const todayLessons = []
  for (const lesson of lessons) {
    if (!lesson.node) continue
    for (let lessonIndex = 1; lessonIndex <= 9; lessonIndex++) {
      // 8 lessons
      const currentLessonDay = lesson.node["lesson" + lessonIndex]
      if (!currentLessonDay) continue
      for (let dateIndex = 1; dateIndex <= 3; dateIndex++) {
        // 3 dates
        const currentLesson = new Date(currentLessonDay["date" + dateIndex])
        if (isToday(currentLesson)) {
          todayLessons.push(
            `Cours ${lessonIndex} - ${currentLesson.toLocaleTimeString(
              "fr-FR",
              { hour: "2-digit", minute: "2-digit" }
            )}`
          )
        }
      }
    }
  }
  return todayLessons
}

const IndexPage = ({ data }) => {
  const currentDay = days[new Date().getDay()]
  let todayTests = []
  if (data.strapiHorairesDeCode[currentDay]) {
    todayTests = data.strapiHorairesDeCode[currentDay]
      .map(t => t.time.substr(0, 5))
  }

  const todayLessons = getTodayLesson(data.allStrapiCoursTheoriques.edges)


  const [actuIndex, setActuIndex] = useState(0);
  /**
   * Navigate through actus
   * @param {string} type - "next" or "prev"
   */
  const showActu = (type) => {
    if (type === 'next') {
      if ((actuIndex + 1) < data.allStrapiActualites.edges.length) setActuIndex(actuIndex + 1);
    } else if (type === 'prev') {
      if (actuIndex > 0) setActuIndex(actuIndex - 1);
    } else {
      console.warn('Unknown "type" parameter. It must be "next" or "prev".')
    }
  }

  /**
   * Get disabled class
   * @param {string} type - "next" or "prev"
   */
  const getActuClass = (type) => {
    if (((type === 'next') && ((actuIndex + 1) >= data.allStrapiActualites.edges.length)) ||
    (type === "prev" && actuIndex <= 0)) return 'opacity-20'
  }

  return (
    <Layout>
      <Seo title="Accueil" description="" />

      {/* Hero */}
      <div className="py-24 mb-16">
        <div className="hidden md:block w-1/3 mt-8 lg:mt-4 lg:w-1/2 xl:-mt-8  absolute right-0  ">
          <div className="absolute left-0 top-0 w-1/3 h-full gradient-transparent"></div>
          <img
            src={road}
            width={500}
            className="w-full"
            alt="Road graphic" />
        </div>

        <h1 className="text-3xl text-primary uppercase mb-4">École de conduite</h1>
        <img
          src={signature}
          width={470}
          height={160}
          alt="Yvan Blin" />
      </div>

      {/* Actualités */}
      <div className="grid md:grid-cols-2 gap-12 lg:gap-32 mb-30">
        <div>
          <h3 className="uppercase mb-2">Actualités</h3>
          <div className="card">
            <ul>
              {data.allStrapiActualites.edges.map((document, index) => (
                <li key={document.node.id} className={index === actuIndex ? 'block' : 'hidden'}>
                  <h4 className="text-primary mb-2 mt-0">
                    {document.node.title}
                  </h4>
                  <p dangerouslySetInnerHTML={{ __html: document.node.content.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></p>
                </li>
              ))}
            </ul>
            <div className={`text-right mt-3 opacity-75 ${data.allStrapiActualites.edges.length > 1 ? 'block' : 'hidden'}`}>
              <button className={`mr-2 ${getActuClass('prev')}`} onClick={() => showActu('prev')}>←</button>
              <span>{actuIndex + 1} / {data.allStrapiActualites.edges.length}</span>
              <button className={`ml-2 ${getActuClass('next')}`} onClick={() => showActu('next')}>→</button>
            </div>
          </div>
        </div>
        <div>
          <h3 className="uppercase mb-2">Les cours du jour</h3>
          <div className="grid grid-cols-2 gap-4">
            <div className="card">
              <Link to="/les-horaires/tests-de-code" className="group">
                <img
                  src={next}
                  width={12}
                  className="float-right mt-1.5 opacity-0 group-hover:opacity-100"
                  alt=""
                />
                <h4 className="text-primary mb-2 mt-0">Tests de code</h4>
              </Link>
              <div>
              {todayTests.length === 0 &&
                <p className="opacity-50 text-sm leading-4">Pas de test de code aujourd'hui</p>
              }
              {todayTests.length > 0 &&
                <p>{todayTests.join(' - ')}</p>
              }
              </div>
            </div>
            <div className="card">
              <Link to="/les-horaires/cours-theoriques" className="group">
                <img
                  src={next}
                  width={12}
                  className="float-right mt-1.5 opacity-0 group-hover:opacity-100"
                  alt=""
                />
                <h4 className="text-primary mb-2 mt-0">Cours théoriques</h4>
              </Link>
              <div >
                {todayLessons.length === 0 &&
                  <p className="opacity-50 text-sm leading-4">Pas de cours théorique aujourd'hui</p>
                }
                {todayLessons.length > 0 &&
                  <ul>
                    {todayLessons.map(lesson => (
                      <li key={lesson}>{lesson}</li>
                    ))}
                  </ul>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Formations */}
      <div className="py-24 ">
        <h2 className="uppercase text-4xl mb-12">
          Nos formations
        </h2>
        <div className="grid sm:grid-cols-3 gap-4">
          <div className="card font-bold text-center">
            <img src={permisB} width="100" className="inline-block mt-8 mb-4" alt="" />
            <h3 className="uppercase">Permis B</h3>
            <Link to="/nos-formations/permis-b" className="font-normal block mt-4 mb-4 text-sm text-primary hover:underline">En savoir plus</Link>
          </div>
          <div className="card font-bold text-center">
            <img src={conduiteAccompagnee} width="100" className="inline-block mt-8 mb-4" alt="" />
            <h3 className="uppercase">Conduite accompagnée</h3>
            <Link to="/nos-formations/conduite-accompagnee" className="font-normal block mt-4 mb-4 text-sm text-primary hover:underline">En savoir plus</Link>
          </div>
          <div className="card font-bold text-center">
            <img src={conduiteSupervisee} width="100" className="inline-block mt-8 mb-4" alt="" />
            <h3 className="uppercase">Conduite supervisée</h3>
            <Link to="/nos-formations/conduite-supervisee" className="font-normal block mt-4 mb-4 text-sm text-primary hover:underline">En savoir plus</Link>
          </div>
        </div>
        <div className="text-center mt-12">
          <Link to="/nos-formations/" className="btn">Toutes nos formations</Link>
        </div>
      </div>

      {/* Contact */}
      <div className="py-24">
        <h2 className="uppercase text-4xl mt-8">
          Nous contacter
        </h2>
        <ContactForm horairesBureau={data.strapiHorairesDeBureau} />
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
    allStrapiActualites(sort: {fields: created_at, order: DESC}) {
      edges {
        node {
          id
          title
          content
        }
      }
    }
    strapiHorairesDeCode {
      monday {
        time
      }
      tuesday {
        time
      }
      wednesday {
        time
      }
      thursday {
        time
      }
      friday {
        time
      }
      saturday {
        time
      }
    }
    allStrapiCoursTheoriques {
      edges {
        node {
          id
          lesson1 {
            date1
            date2
            date3
          }
          lesson2 {
            date1
            date2
            date3
          }
          lesson3 {
            date1
            date2
            date3
          }
          lesson4 {
            date1
            date2
            date3
          }
          lesson5 {
            date1
            date2
            date3
          }
          lesson6 {
            date1
            date2
            date3
          }
          lesson7 {
            date1
            date2
            date3
          }
          lesson8 {
            date1
            date2
            date3
          }
          lesson9 {
            date1
            date2
            date3
          }
        }
      }
    }
    strapiHorairesDeBureau {
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
    }
  }
`
